body {
  margin: 0;
  overflow: hidden;
}

.react-tel-input.flag-dropdown.open {
  border-radius: 8px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 8px;
}

.rdp-root {
  --rdp-accent-color: #B4EE2B;
  --rdp-accent-background-color: #EEEEEE; 
  --rdp-range_start-color: #000;
  --rdp-font-family: 'Open Sans';
  --rdp-selected-border: none;
}

.rdp-day_button {
  font-family: 'Open Sans';
}

.rdp-weekday {
  font-weight: bold;
}

.rdp-outside {
  color: #838383;
}

.rdp-month_caption {
  justify-content: center;
}

