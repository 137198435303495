.custom-info-window {
  padding: 10px;
  max-width: 200px;
}

.custom-info-window h3 {
  margin: 0 0 10px 0;
}

.custom-info-window p {
  margin: 0;
}

.gm-style-iw {
  border: 2px solid #9e9e9e !important;
  background-color: white !important;
  color: black !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3) !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw-t::after {
  display: none !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}
