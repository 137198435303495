.gmnoprint.gm-style-mtc-bbw {
  padding-left: 500px;
}

.gm-style-moc {
  padding-left: 500px;
}

.gm-style a[href^="https://maps.google.com/maps"]
{
  margin: 0px 420px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.gm-ui-hover-effect {
  display: none !important;
  padding: 0px 0px 0px 0px;
}

.gm-style .gm-style-iw-c {
  padding: 12px !important;
  background-color: black !important;
  border-radius: 10px;
  border: none !important;
}

.gm-style-iw-ch {
  display: none !important;
}

.gm-style .gm-style-iw-tc::after {
  background: black !important;
}
